$(document).foundation();
$(document).ready(function() {

    inlineSVGs([
        '.footer__social img',
        '.burger-menu__footer-social img',
        '.resort-burger-menu__footer-social img'
    ]);

    initLanguages();
    initAnimateInView();
    initVerticalCenter();
    countIndexTabItems();
    initSliders();
    initBooking();
    initDatePicker();
    initAccordions();
    initHeader();
    sliderVimeoVideo();
    vimeoVideo();
    initSocialWall();
    initExperienceVideo();
    initExperienceAlternation();
    addMarginWhenNoSlider();
    hideHeaderButtonsOpenBookingWidget(); // shows and hides booking form, removes header buttons when open due to opacity
    window['initExperiencesSlider']();
    window['initOffersSlider']();
    initGallery();
    initContactForms();
    initWindowResize();
    initPreRegister();
    initRegisterDatePicker();
    openLanguageDropdown();
    initOffersDetails();
    initAccommodationThumbnail();
    initNotificationBanner();
    initMoveHeaderDown();
    initMaps();


});


$(document).on('click', '.cookiebar-close',function(e){
    e.preventDefault();
    $('.banner').removeAttr('style');
    $('.banner-content').removeAttr('style');
    $('.notification-banner').remove();
    $(".cookie-message").hide();
});

function initAccommodationThumbnail() {

    $('.rd-index__item-thumbnail a').on('click', function (e) {
      e.preventDefault();

      if ($(window).width() > 640) {

      var imagelink = $(this).attr('href');

      var modal = '<div id="thumbnail-modal" class="thumbnail-modal full reveal loading" data-reveal><button class="thumbnail-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="thumbnail-modal__image"><img src="' + imagelink + '"/></div></div>';

          var $modal = $(modal);

          $('body').append($modal);

          $('#thumbnail-modal').foundation();

          $('#thumbnail-modal').foundation('open');

          $modal.on('closed.zf.reveal', function () {
             $modal.remove();
         });
         }
      });

}


function initMoveHeaderDown() {
  var bannerHeight = $('.notification-banner').height();

  if ($('.notification-banner').length > 0) {
    $('.banner').css('top', bannerHeight + "px");
    $('.banner-content').css('padding-top', bannerHeight + "px");
  }
}

function initNotificationBanner() {

  if ($('.notification-banner').length > 0) {
    var notificationBannerKeepHiddenFor = $('.notification-banner').data('notification-banner-keep-hidden-for-n-days') || 0

    if (!Cookies.get('hasSeenBanner')) {
      $('.cookie-message').css("display", "block");
      $('.header, .resort-header').addClass('banner');
      $('#mainContent').addClass('banner-content');

      if (notificationBannerKeepHiddenFor > 0) {
        Cookies.set('hasSeenBanner', 'true', {expires: notificationBannerKeepHiddenFor});
      }
    }else{
        $('.notification-banner').remove();
    }

    $( document ).on('click', '.notification-banner__close',function() {
        $('.banner').removeAttr('style');
        $('.banner-content').removeAttr('style');
        $('.notification-banner').remove();
    });
  }

}

$(window).on('resize', function() {
	initMoveHeaderDown();
	initNotificationBanner();
})



// $("#footer").css('top', $("#text").height() + "px");

// function initNotificationBanner() {
// if ( $('#notification-banner').length > 0 ) {
// var popupDelay = $('#notification-banner').data('popup-delay') || 0;
// var popupKeepHiddenFor = $('#notification-banner').data('popup-keep-hidden-for-n-days') || 0;
//
// if (!Cookies.get('hasSeenPopup')) {
// setTimeout(function () {
// $('#notification-banner').foundation('open');
//
// if (popupKeepHiddenFor > 0) {
// Cookies.set('hasSeenPopup', 'true', {expires: popupKeepHiddenFor});
// }
// }, popupDelay)
// }
// }
// }

function openLanguageDropdown() {
    $('.header__language button').click(function () {
        $('.language-select').toggleClass('open');
    });
}

function countIndexTabItems() {
    $('.tabs-title').each(function() {
        var tabName = $(this).children().data('tabs-target');
        var count = $('#'+ tabName +' > div.rd-index__item-wrap').length;
        $(this).children().find('span').text("("+count.toString()+")");
    })
}

function initContactForms() {
    $('.validate-form').validate();
}

function addMarginWhenNoSlider() {
    if ( $('.slider').length < 1 ) {
        if ( $(window).outerWidth() < 640 ) {
            $('main').children().first().css("margin-top", '160px');
        } else {
            $('main').children().first().css("margin-top", '210px');
        }
    }
}

function initWindowResize() {
    $(window).on('resize', function() {
        addMarginWhenNoSlider();
    });
}


function initExperienceAlternation() {
    $('div.experiences__grid-item-container:visible:odd').children('.experiences__grid-item-container-info').addClass('large-order-2');
    $('div.experiences__grid-item-container:visible:even').children('.experiences__grid-item-container-info').addClass('large-order-1');
    $('div.experiences__grid-item-container:visible:odd').children('.experiences__grid-item-container-video').addClass('large-order-1');
    $('div.experiences__grid-item-container:visible:even').children('.experiences__grid-item-container-video').addClass('large-order-2');

    $('.filter-controls button, .resort-filter-controls button').on('click', function() {
        $('.experiences__grid-item-container-info').removeClass('large-order-2');
        $('.experiences__grid-item-container-info').removeClass('large-order-1');
        $('.experiences__grid-item-container-video').removeClass('large-order-1');
        $('.experiences__grid-item-container-video').removeClass('large-order-2');
        setTimeout(function() {
            $('div.experiences__grid-item-container:visible:odd').children('.experiences__grid-item-container-info').addClass('large-order-2');
            $('div.experiences__grid-item-container:visible:even').children('.experiences__grid-item-container-info').addClass('large-order-1');
            $('div.experiences__grid-item-container:visible:odd').children('.experiences__grid-item-container-video').addClass('large-order-1');
            $('div.experiences__grid-item-container:visible:even').children('.experiences__grid-item-container-video').addClass('large-order-2');
        },500);
    })
}

function initExperienceVideo() {

    $('.iframe-wrap').each(function() {
        var itemHeight = $(this).parents().find('.experiences__grid-item-container-video').height();
        $(this).height(itemHeight);

        $(window).resize(function() {
            $('.iframe-wrap').each(function() {
                $(this).height($(this).parents().find('.experiences__grid-item-container-video').height());
            });
        });
    });

    if ( $(window).outerWidth() < 640 ) {
        if ( $('.experiences__grid-item-container-video iframe').length > 0 ) {
            coverViewport('.experiences__grid-item-container-video iframe');
            $(window).on('resize', function () {
                coverViewport('.experiences__grid-item-container-video iframe');
            });
        }
    }

}

function vimeoVideo() {
    if ( $('.experiences__grid-item .iframe-wrap, .full-width-video .iframe-wrap').length > 0 ) {
        $('.experiences__grid-item, .full-width-video').each(function() {
            $(this).find('.play-button').click(function() {
                var iframePlayer = $(this).parent().find('.iframe-wrap iframe#vimeo-player');
                var player = new Vimeo.Player(iframePlayer);
                var play = $(this);
                player.getPaused().then(function(paused) {
                    if ( paused == true ) {
                        player.play();
                        play.hide();
                    }
                })
            })
        });
    }
}

function initSocialWall() {

    $(window).resize(function() {
        $('.social__wall-item-wrap').each(function() {
            $(this).height($(this).width());
        });
    });


    $('.social__wall-item-wrap').each(function() {
        $(this).height($(this).width());
    })


    var socialItem = $('.social__wall-item-wrap');

    socialItem.each(function() {
        if ($(this).data('bg') == 'wild-orange') {
            $(this).css('background-color', '#FF5000');
        }

        if ($(this).data('bg') == 'medium-grey') {
            $(this).css('background-color', '#bcb9bb');
        }

        if ($(this).data('bg') == 'greige') {
            $(this).css('background-color', '#dedcdd4d');
        }
    })

}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}



function initBooking() {
	$('.booking').on('opened.zf.offcanvas', function () {
		$('.booking__form').fadeIn(1200);
	});


	if ( $('.slider').length > 0 ) {
		var userHasInteracted = false;

		$(document).on('click mousedown touchstart scroll', function () {
			userHasInteracted = true;
		});

		if ((!userHasInteracted) && ( $('body').hasClass('homepage') )  && (window.outerWidth > 640)) {
			// Slide out the booking widget if the user has not clicked, touched or scrolled anything but only for medium and larger screen sizes
			setTimeout(function () {
				$('.booking').foundation('toggle');
                setOpacity('header__buttons',0);
				setTimeout(function () {
					if (!userHasInteracted) {
						$('.booking').foundation('toggle');
                        setOpacity('header__buttons',1);
						$('.booking__form').fadeOut(1200);
					}
				}, 6000);
			}, 500);
		}
	}

	// Change the action attribute of the booking form depending on the selected property
    // If no property is selected then go to the group level search site
	$('.booking__form input[type="submit"]').on('click', function (event) {
		$bookingForm = $('.booking__form form');
		var chosenResort = $('select[name="hotelid"]').val();

		if ( (chosenResort === null) || (chosenResort === '') ) {
            $(".error-msg").fadeIn();
            event.preventDefault();
		} else {
            if(chosenResort == 110487 || chosenResort == 85302 || chosenResort == 77173){
                event.preventDefault();
                
                var children = $("select[name='Children']").val();
                var discount = $("#promo-code").val();
                var local = "en-US";
                var hotelId = "46356";
                var config = "SW";
                var theme = "SW";
                var chain = "32488";
                var adults = $("select[name='Adults']").val();
                var checkIn = $("input[name='DateIn']").val();
                var checkOut = $("input[name='DateOut']").val();

                if(checkIn){
                    checkIn = new Date(checkIn);
                    var formated = checkIn.getFullYear()+"-"+(checkIn.getMonth() + 1)+"-"+checkIn.getDate()
                    checkIn = formated;
                }
                if(checkOut){
                    checkOut = new Date(checkOut);
                    var formated = checkOut.getFullYear()+"-"+(checkOut.getMonth() + 1)+"-"+checkOut.getDate()
                    checkOut = formated;
                }

                if(chosenResort == 85302){
                    hotelId = "46409";
                    config = "SSIF";
                    theme = "SSIF";
                }

                if(chosenResort == 77173){
                    hotelId = "46388";
                    config = "SSO";
                    theme = "SSO";
                }
                
                if($("input[name='languageid']").length > 0){
                    var langId = $("input[name='languageid']").val();
                    if(langId == 15){
                        local = "ar-EG";
                    }else if(langId == 5){
                        local = "zh-CN";
                    }else if(langId == 3){
                        local = "fr-FR";
                    }else if(langId == 7){
                        local = "de-DE";
                    }else if(langId == 9){
                        local = "ru-RU";
                    }else if(langId == 2){
                        local = "es-ES";
                    }
                }
                var url = "https://reservations.sunsiyam.com/?Hotel="+hotelId+"&Chain="+chain+"&config="+config+"&theme="+theme+"&locale="+local+"&arrive="+checkIn+"&depart="+checkOut+"&adult="+adults+"&child="+children+"&discount="+discount;
                if($(window).outerWidth() >= 768){
                    window.open(url, '_blank');
                }else{
                    window.location.href = url;
                }
            }else{
                if($(window).outerWidth() >= 768){
                    $bookingForm.attr('target', "_blank");
                }
                $bookingForm.attr('action', $bookingForm.data('base-url') + chosenResort);
            }
        }
	});

    $('.booking__form select[name="hotelid"]').on('change', function () {
		var chosenResort = $(this).val();
        $("#nationality").closest('.cell').fadeOut();
        $("#nationality").prop('disabled', true);
        $(".error-msg").fadeOut();
		if (chosenResort !== null && chosenResort === '96941') {
            $("#nationality").closest('.cell').fadeIn();
            $("#nationality").prop('disabled', false);
        }
	});

    var checkSelectedResorts = setInterval(function() {
        if($('.booking__form select[name="hotelid"]').length > 0){
            clearInterval(checkSelectedResorts);
            $("#nationality").closest('.cell').fadeOut();
            $("#nationality").prop('disabled', true);
            var chosenResort = $('.booking__form select[name="hotelid"]').val();
            if (chosenResort !== null && chosenResort === '96941') {
                $("#nationality").closest('.cell').fadeIn();
                $("#nationality").prop('disabled', false);
            }
        }
    }, 100);
    
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}



// Get more translations from here:
// https://github.com/fengyuanchen/datepicker/tree/master/i18n
$.fn.datepicker.languages['en-US'] = {
   format: 'mm/dd/yyyy'
};

$.fn.datepicker.languages['ru-RU'] = {
   format: 'dd.mm.YYYY',
   days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
   daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
   daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
   months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
   monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
   weekStart: 1,
   startView: 0,
   yearFirst: false,
   yearSuffix: ''
};

$.fn.datepicker.languages['ar-AE'] = {
   format: 'dd/mm/yyyy',
   days: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
   daysShort: ['أحد', 'أثنين', 'ثلاثاء', 'اربعاء', 'خميس', 'جمعة', 'سبت'],
   daysMin: ['أ', 'ث', 'ث', 'أ', 'خ', 'ج', 'س'],
   weekStart: 1,
   months: ['كانون الثاني', ' فبراير', 'مارس', 'أبريل', 'قد', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', ' اكتوبر', ' نوفمبر', 'ديسمبر'],
   monthsShort: ['كانون الثاني', ' فبراير', 'مارس', 'أبريل', 'قد', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', ' اكتوبر', ' نوفمبر', 'ديسمبر'],
 };



var datepickerLanguage = 'en-US';
var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];

if ( window.location.href.indexOf('/ru/') > -1 ) {
   datepickerLanguage = 'ru-RU';
   monthNames = $.fn.datepicker.languages['ru-RU']['months'];
   dayNames = $.fn.datepicker.languages['ru-RU']['days'];
}

if ( window.location.href.indexOf('/ar/') > -1 ) {
   datepickerLanguage = 'ar-AE';
   monthNames = $.fn.datepicker.languages['ar-AE']['months'];
   dayNames = $.fn.datepicker.languages['ar-AE']['days'];
}


function initDatePicker() {
    $('.custom-select select').selectric();

    var bookingContainer = $('.booking__form form');

    var nowTemp = new Date();
    var tomorrow = new Date();
        tomorrow.setDate(nowTemp.getDate() + 1);

    // Bookings start 7 days in advance *** set to zero for ticket 1310 ***
    nowTemp = addDays(nowTemp, 0);
    tomorrow = addDays(tomorrow, 0);

    var $checkInField = bookingContainer.find('#check-in');
    var $checkOutField = bookingContainer.find('#check-out');

    $checkInField.on('hide.datepicker', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');
        var dayAfterCheckIn = new Date(checkInDate);
            dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

        // Ensure checkout comes after checkin.
        // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
        if ( checkInDate >= checkOutDate ) {
            $checkOutField.datepicker('setDate', dayAfterCheckIn);
        }

        $checkOutField.datepicker('setStartDate', dayAfterCheckIn);

        $checkOutField[0].focus();
    });

    var checkin = $checkInField.datepicker({
        format: 'dd',
        autoPick: true,
        autoHide: true,
        date: null,
        startDate: nowTemp,
        language: datepickerLanguage,
        pick: function (e) {
            // User-facing date fields
            $('#check-in-month').text( monthNames[e.date.getMonth()] );
            $('#check-in-year').text( e.date.getFullYear() );

            // Formatted date for the booking engine
            $('input[name="DateIn"]').val( pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getDate() + '/' + e.date.getFullYear() );
        }
    });

    var checkout = $checkOutField.datepicker({
        format: 'dd',
        autoPick: true,
        autoHide: true,
        date: null,
        startDate: tomorrow,
        language: datepickerLanguage,
        pick: function (e) {
            // User-facing date fields
            $('#check-out-month').text( monthNames[e.date.getMonth()] );
            $('#check-out-year').text( e.date.getFullYear() );

            // Formatted date for the booking engine
            $('input[name="DateOut"]').val( pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getDate() + '/' + e.date.getFullYear() );
        }
    });


    // Allow clicking the full container to open the calendar
    $('.date-wrap').on('click', function () {
        var dateField = $(this).find('#check-in, #check-out');

        // And for some reason it needs a delay before the datepicker lets me trigger the `open` method
        setTimeout(function () {
            dateField.datepicker('show');
        }, 100);

    });
}

function setOpacity(className, opacity){
    $('.'+className).css('opacity', opacity);
}

function hideHeaderButtonsOpenBookingWidget() {
    $(document).on('click', '.header__buttons [data-toggle="booking"], .resort-header__buttons [data-toggle="booking"], .burger-booking', function() {
        setTimeout(function () {
            if($(".booking").hasClass('is-open')){
                setOpacity('header__buttons',0);
            }
        }, 600);
    })

    $('.header__buttons-mobile [data-toggle="booking"]').on('click', function() {
        setTimeout(function () {
            if($(".booking").hasClass('is-open')){
                setOpacity('header__buttons-mobile',0);
            }
        }, 600);
    })

    $('.js-off-canvas-overlay.is-overlay-fixed, .booking__close').on('click', function() {
        $('.booking').foundation('close');
		$('.booking__form').fadeOut(1200);
        setTimeout(function () {
            setOpacity('header__buttons',1);
        }, 500);
    })

    $('.js-off-canvas-overlay.is-overlay-fixed, .booking__close').on('click', function() {
        $('.booking__form').fadeOut(1200);
		setTimeout(function () {
            setOpacity('header__buttons-mobile',1);
        }, 500);
    })

    // Hide the booking widget if the burger menu gets opened
    $('.header__open').on('click', function () {
	    $('.booking').foundation('close');
		$('.booking__form').fadeOut(1200);
		setTimeout(function () {
            setOpacity('header__buttons',1);
        }, 500);
    });
}

function initHeader() {

	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}

    setHeaderTransparency();
    $(window).scroll(setHeaderTransparency);

    // Hide the burger nav when clicking outside
    // $('#burger-menu').on('click', function () {
    //     $('#burger-menu').foundation('close');
    // });
}

function setHeaderTransparency() {
    var sticky = $('.sticky'),
    scrollOffset = $(window).scrollTop();

    if ( $('body.no-slider').length > 0 ) {

        if (scrollOffset >= 70) {
            sticky.addClass('fixed');
            $('.header__logo .logo-scroll').css('display', 'block');
            $('.header__logo .logo-grey').css('display', 'none');
            $('.header__logo .logo').css('display', 'none');

            $('.resort-header__logo .logo-scroll').css('display', 'block');
            $('.resort-header__logo .logo-no-slider').css('display', 'none');
            $('.resort-header__logo .logo').css('display', 'none');
            $('.resort-header__buttons .white-outline, .resort-header__buttons-mobile .white-outline, .resort-header__group-link, .resort-header__group-link-mobile, .resort-header__open').addClass('stuck');

        } else {
            sticky.removeClass('fixed');
            $('.header__logo .logo-grey').css('display', 'block');
            $('.header__logo .logo-scroll').css('display', 'none');
            $('.header__logo .logo').css('display', 'none');

            $('.resort-header__logo .logo-no-slider').css('display', 'block');
            $('.resort-header__logo .logo-scroll').css('display', 'none');
            $('.resort-header__logo .logo').css('display', 'none');
            $('.resort-header__buttons .white-outline, .resort-header__buttons-mobile .white-outline, .resort-header__open').removeClass('stuck');
        }

    } else {

        if (scrollOffset >= 180) {
            sticky.addClass('fixed');
            $('.header__logo .logo').css('display', 'none');
            $('.header__logo .logo-scroll').css('display', 'block');
            $('.header__buttons .white-outline, .header__buttons-mobile .white-outline').addClass('stuck');

            $('.resort-header__logo .logo').css('display', 'none');
            $('.resort-header__logo .logo-no-slider').css('display', 'none');
            $('.resort-header__logo .logo-scroll').css('display', 'block');
            $('.resort-header__buttons .white-outline, .resort-header__buttons-mobile .white-outline').addClass('stuck');
        } else {
            sticky.removeClass('fixed');
            $('.header__logo .logo').css('display', 'block');
            $('.header__logo .logo-scroll').css('display', 'none');
            $('.header__buttons .white-outline, .header__buttons-mobile .white-outline').removeClass('stuck');

            $('.resort-header__logo .logo').css('display', 'block');
            $('.resort-header__logo .logo-scroll').css('display', 'none');
            $('.resort-header__logo .logo-no-slider').css('display', 'none');
            $('.resort-header__buttons .white-outline, .resort-header__buttons-mobile .white-outline').removeClass('stuck');
        }
    }
}

function initAccordions() {

    $('.burger-menu__nav-accordion').accordiom({
        openAll: true
    });
    $('.burger-menu__nav-accordion-inner').accordiom({
        openAll: true,
        autoClosing: false
    });

    $('.resort-burger-menu__accordion-primary').accordiom({
        showFirstItem: true
    });

    $('.resort-burger-menu__accordion-secondary').accordiom({
        showFirstItem: true
    });

    $('.resort-burger-menu__accordion-main').accordiom({
        showFirstItem: true
    });

    $('.booking__direct').accordiom({
        showFirstItem: false,
        speed: 700
    });

    $('.experiences-filter-accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: false
    });

    $('.offers__filters-accordion').accordiom({
        showFirstItem: false
    })

    $('.blog-index__filters-accordion').accordiom({
        showFirstItem: false
    })

    $('.rd-index__tabs-mobile-accordion').accordiom({
        showFirstItem: false
    })
}

function sliderVimeoVideo() {
    if ( $('.slider__slide .iframe-wrap').length > 0 ) {
        var iframePlayer = $('iframe#vimeo-player');
        var player = new Vimeo.Player(iframePlayer);

        // Hide play button if video is autoplaying but do show the volume controls
        if ( iframePlayer.attr('src').indexOf('autoplay=1') > -1 ) {
	        $('.slider .play-button').hide();
	        $('.video-bar').css('display', 'block');
            $('#volumeSwitch').toggleClass('off');
            $('#volumeOn').css('visibility', 'hidden');
            $('#volumeOff').css('visibility', 'visible');
            $("#volumeSwitch").prop('checked', true);
        }

        $('.slider__slide .play-button').click(function() {
            var playButton = $(this);
            player.getPaused().then(function(paused) {
                if ( paused == true ) {
                    player.play();
                    playButton.hide();
                    $('.video-bar').css('display', 'block');
                }
            })
        });

        $('.video-bar input[type="checkbox"]').on('change', function() {
            player.getVolume().then(function(volume) {
                if ( (volume == 0) || (volume == 1) ) {
                    player.setVolume(0.66);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').css('visibility', 'hidden');
                    $('#volumeOn').css('visibility', 'visible');
                    $("#volumeSwitch").prop('checked', false);
                } else {
                    player.setVolume(0);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').css('visibility', 'visible');
                    $('#volumeOn').css('visibility', 'hidden');
                    $("#volumeSwitch").prop('checked', true);
                }
            })
        })
    }
}

function initSliders() {

    $('.slider__inner').reformSlider({
        autoPlay: true,
        speed: 5000,
        animationSpeed: 2000,
        arrows: false,
        pagination: true
    });

    if ( $('.slider__slide iframe').length > 0 ) {
        $('.slider').addClass('slider--has-video');
        coverViewport('.slider__slide iframe', true);
        $(window).on('resize', function () {
            coverViewport('.slider__slide iframe', true);
        });
    }

    if ( $('.slider__slide').length === 0 ) {
        $('.slider').addClass('slider--no-slider');
    }

    $('.resorts-slider__slide-container').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a>' + title + '</a>';
        },
        responsive: [
        {
            breakpoint: 640,
            settings: {
                dots: true,
                arrows: false,
                customPaging: function(slider, i) {
                    var title = $(slider.$slides[i]).data('title');
                    return '<a></a>';
                }
            }
        }
        ]
    });

    $('.tabbed-content__slider').slick({
        dots: true,
        arrows: false,
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a>' + title + '</a>';
        },
        responsive: [
        {
            breakpoint: 640,
            settings: {
                arrows: true
            }
        }
        ]
    });

    $('.featured-experience-slider__slide-container').slick({
        arrows: true,
        responsive: [
        {
            breakpoint: 640,
            settings: {
                dots: true,
                arrows: false
            }
        }
        ]
    });

    var moreSlides = $('.more-slider__slide').length;
    if (moreSlides == 1) {
        $('.more-slider__inner').slick({
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: false
                }
            }
            ]
        })
    } else if(moreSlides == 2) {
        $('.more-slider__inner').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: false
                }
            }
            ]
        })
    } else if(moreSlides >=3) {
        $('.more-slider__inner').slick({
            centerMode: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: '0px',
            arrows: true,
            infinite: true,
            responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '40px',
                    arrows: false
                }
            }
            ]
        })
    }


    $('.offers-slider-mobile__container').slick({
        dots: false,
        arrows: true,
        appendArrows: $('.offers-slider-mobile__slide-img-offer-offer-cta-arrows'),
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    })

    $('.offers-slider-mobile__container .slick-prev').on('click', function(){   //appended arrows won't work inside slide
        $('.offers-slider-mobile__container').slick("slickPrev");
    });

    $('.offers-slider-mobile__container .slick-next').on('click', function(){
        $('.offers-slider-mobile__container').slick("slickNext");
    });

    $('.experiences-slider__nav-slider').slick({
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    })

    $('.slider__cta .cta').on('click', function(e){
        e.preventDefault();
        var spacingAtTop = $("header").innerHeight();
        var sliderWrapper = $('#mainContent > .intro').offset().top;
        if($(this).hasClass("experiences_cta")){
            sliderWrapper = $('.experiences-slider__wrapper').offset().top;
        }
        if($(this).hasClass("accommodations_cta")){
            sliderWrapper = $('.resorts-accommodations__wrapper').offset().top;
        }
        var offsetTop = sliderWrapper - spacingAtTop;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    });

    $('.intro__container .accommodations_cta').on('click', function(e){
        e.preventDefault();
        var spacingAtTop = $("header").innerHeight();
        var sliderWrapper = $('.resorts-accommodations__wrapper').offset().top;
        var offsetTop = sliderWrapper - spacingAtTop;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    });

    if($(".accomodations-slides").length > 0){
        $('.accomodations-slides').each(function(){
            if($(this).find(".accomodations-slides-item").length > 1){
                $(this).slick({
                    dots: false,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                });
            }
        });
        
    }

    if($(".resorts-accommodations__wrapper .rd-index__intro").length > 0){
        var currentPage = window.location.href.split('?')[0].split('#')[0];
        var contentPage = currentPage + 'accommodation/';
        $('.resorts-accommodations__wrapper .rd-index__intro').load(contentPage + ' .intro', function(response, status, xhr) {
            if (status == "success") {
                $('.resorts-accommodations__wrapper .rd-index__intro h1').wrapInner("<h2></h2>");
                $('.resorts-accommodations__wrapper .rd-index__intro h2').unwrap();
            }
        });
    }

    if($(".resorts-accommodations .rd-index__item-wrap").length > 0){
        $('.resorts-accommodations .rd-index__item-wrap').each(function(){
            var item = $(this);
            var readMoreLink = window.location.origin+item.find('.rd-index__item-cta .cta-wrapper a.cta.orange-outline').attr('href');
            item.find('.resortText').load(readMoreLink + ' .detail-content__main p', function() {});
            item.find('.resortFeatures').load(readMoreLink + ' .detail-content__sidebar', function() {
                item.find('.resortFeatures h2').unwrap();
            });
        });

        $(".resorts-accommodations").slick({
            autoplay: false,
            arrows: true,
            buttons: false,
            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1
        });
    }

    $('.resorts-accommodations-tabs .tabs-title a').on('click', function(event) {
        event.preventDefault();
        var targetId = $(this).attr('data-tabs-target');
        $('.tabs-title a').each(function() {
            $(this).parent().removeClass('is-active');
            $(this).attr('aria-selected', 'false').attr('tabindex', '-1');
        });
        $(this).parent().addClass('is-active');
        $(this).attr('aria-selected', 'true').attr('tabindex', '0');
        
        $('.tabs-panel').removeClass('is-active');
        $('#' + targetId).addClass('is-active');

        $('.resorts-accommodations.slick-initialized').each(function() {
            $(this).slick("unslick");
        });
        $('.resorts-accommodations').each(function() {
            $(this).slick({
                autoplay: false,
                arrows: true,
                buttons: false,
                infinite: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        });
    });
}

var defaultSettings = {
    experiencesSliderSingleImage: {
        dots:true,
        arrows: true,
        appendArrows: $('.experiences-slider__nav'),
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        asNavFor: '.experiences-slider__nav-slider',
        responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
		        slidesToScroll: 1
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
		        slidesToScroll: 1
            }
        }
        ]
    },
    offersSliderSingleImage: {
        dots: true,
        arrows: true,
        appendArrows: $('.offers-slider__nav'),
        infinite: true,
        slidesToShow: 1
    }
}


var settings;
if (typeof projectSettings === 'undefined') {
    settings = defaultSettings;
} else {
    // Update the default settings with the custom ones
    settings = $.extend(true,{}, defaultSettings, projectSettings);
}


var initExperiencesSlider = function () {
    $('.experiences-slider__nav--has-scrollbar').append('<div class="experiences-slider__scrollbar"><div class="experiences-slider__scrollbar-thumb"></div></div>');

    var dragStarted = false;
    var dragStartMousePosition = 0;
    var dragStartThumbPosition = 0;
    var dragDistance = 0;

    $('.experiences-slider__container-wrap').on('init', function (event, slick) {
        setScrollbarThumbSize(this);
        $(window).on('resize', function () {
            setScrollbarThumbSize(this);
        });

        $carousel       = $(this);
        $scrollbar      = $(this).parents().find('.experiences-slider__scrollbar');  //made parent plural to find div
        $scrollbarThumb = $(this).parents().find('.experiences-slider__scrollbar-thumb');  //made parent plural to find div

        $scrollbarThumb.on('mousedown touchstart', function (event) {
            // Drag has started, so note the mouse starting postiion
            dragStarted = true;
            dragStartMousePosition = event.clientX;
            dragDistance = 0;
            $scrollbarThumb.css('transition-duration', '0s');
            dragStartThumbPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];
        });

        $(window).on('mouseup touchend', function (event) {
            if (!dragStarted) {
                return;
            }
            // Finished dragging, so clear temp vals and move the thumb to it's correct position
            dragStarted = false;
            $scrollbarThumb.css('transition-duration', '0.3s');

            var slideCount = $carousel.parent().find('.slick-slide').not('.slick-cloned').length;
            var scrollbarWidth = $carousel.parents().find('.experiences-slider__scrollbar').outerWidth();
            var thumbWidth = scrollbarWidth / slideCount;
            var dragEndPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];

            var dragEndIndex = Math.floor((dragEndPosition / scrollbarWidth) * 10);
            if (dragEndIndex < 0) {
                dragEndIndex = 0;
            } else if (dragEndIndex >= slideCount) {
                dragEndIndex = slideCount-1;
            }

            // finally tell slick what slide to change to
            $carousel.slick('slickGoTo', dragEndIndex, false);
        });

        $(window).on('mousemove touchmove', function (event) {
            if (dragStarted) {
                dragDistance = event.clientX - dragStartMousePosition;
                var newPosition = (event.clientX - dragStartMousePosition) + dragStartThumbPosition*1;

                // Stop it from dragging outside the scrollbar
                if ((newPosition >= 0) && (newPosition < ($scrollbar.outerWidth() - $scrollbarThumb.outerWidth()))) {
                    $scrollbarThumb.css('transform', 'translate(' + (dragDistance + dragStartThumbPosition *1) + 'px,-25%)');
                }
            }
        });
    });

    $('.experiences-slider__container-wrap').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        setScrollbarThumbPosition(this, nextSlide);
    });

    $('.experiences-slider__container-wrap').slick(settings.experiencesSliderSingleImage);

    function setScrollbarThumbSize(carousel) {
        // Divide the width of the scrollbar by the number of slides to get the width of the thumb
        var slideCount = $(carousel).parent().find('.slick-dots li').length;
        var scrollbarWidth = $(carousel).parents().find('.experiences-slider__scrollbar').outerWidth();     //made parent plural to find div
        var thumbWidth = scrollbarWidth / slideCount;
        $(carousel).parents().find('.experiences-slider__scrollbar-thumb').css('width', thumbWidth);  //made parent plural to find div
    }

    function setScrollbarThumbPosition(carousel, nextSlideIndex) {
        // Move the thumb to the correct notch for the current slide index

        var slideIndex;
        if (typeof nextSlideIndex === 'undefined') {
            slideIndex = nextSlideIndex;
        } else {
            slideIndex = $(carousel).slick('slickCurrentSlide');
        }
        var scrollbarThumbWidth = $(carousel).parents().find('.experiences-slider__scrollbar-thumb').outerWidth();      //made parent plural to find div
        var thumbIndex = $(carousel).parents('.experiences-slider').find('.slick-dots li.slick-active').index();
        var thumbPosition = thumbIndex * scrollbarThumbWidth;
        $(carousel).parents().find('.experiences-slider__scrollbar-thumb').css('transform', 'translate(' + thumbPosition + 'px, -25%)');  //made parent plural to find div
    }
}



var initOffersSlider = function () {
    $('.offers-slider__nav--has-scrollbar').append('<div class="offers-slider__scrollbar"><div class="offers-slider__scrollbar-thumb"></div></div>');

    var offerDragStarted = false;
    var offerDragStartMousePosition = 0;
    var offerDragStartThumbPosition = 0;
    var offerDragDistance = 0;

    $('.offers-slider__container').on('init', function (event, slick) {
        setScrollbarThumbSize(this);
        $(window).on('resize', function () {
            setScrollbarThumbSize(this);
        });

        $offerCarousel       = $(this);
        $offerScrollbar      = $(this).parents().find('.offers-slider__scrollbar');  //made parent plural to find div
        $offerScrollbarThumb = $(this).parents().find('.offers-slider__scrollbar-thumb');  //made parent plural to find div

        $offerScrollbarThumb.on('mousedown touchstart', function (event) {
            // Drag has started, so note the mouse starting postiion
            offerDragStarted = true;
            offerDragStartMousePosition = event.clientX;
            offerDragDistance = 0;
            $offerScrollbarThumb.css('transition-duration', '0s');
            offerDragStartThumbPosition = $offerScrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];
        });

        $(window).on('mouseup touchend', function (event) {
            if (!offerDragStarted) {
                return;
            }
            // Finished dragging, so clear temp vals and move the thumb to it's correct position
            offerDragStarted = false;
            $offerScrollbarThumb.css('transition-duration', '0.3s');

            var offerSlideCount = $offerCarousel.parent().find('.slick-dots li').length;
            var offerScrollbarWidth = $offerCarousel.parents().find('.offers-slider__scrollbar').outerWidth();
            var offerThumbWidth = offerScrollbarWidth / offerSlideCount;
            var offerDragEndPosition = $offerScrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];

            var offerDragEndIndex = Math.floor((offerDragEndPosition / offerScrollbarWidth) * 10);
            if (offerDragEndIndex < 0) {
                offerDragEndIndex = 0;
            } else if (offerDragEndIndex >= offerSlideCount) {
                offerDragEndIndex = offerSlideCount-1;
            }

            // finally tell slick what slide to change to
            $offerCarousel.slick('slickGoTo', offerDragEndIndex, false);
        });

        $(window).on('mousemove touchmove', function (event) {
            if (offerDragStarted) {
                offerDragDistance = event.clientX - offerDragStartMousePosition;
                var newPosition = (event.clientX - offerDragStartMousePosition) + offerDragStartThumbPosition*1;

                // Stop it from dragging outside the scrollbar
                if ((newPosition >= 0) && (newPosition < ($offerScrollbar.outerWidth() - $offerScrollbarThumb.outerWidth()))) {
                    $offerScrollbarThumb.css('transform', 'translate(' + (offerDragDistance + offerDragStartThumbPosition *1) + 'px,-25%)');
                }
            }
        });
    });

    $('.offers-slider__container').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        setScrollbarThumbPosition(this, nextSlide);
    });

    $('.offers-slider__container').slick(settings.offersSliderSingleImage);

    // Manual eqqulizer since the Foundation one was too jumpy in the slider
    if ( $('.offers-slider__container').length > 0 ) {
        equaliseOfferSlider();
        $(window).on('rezize', equaliseOfferSlider);
    }

    function equaliseOfferSlider() {
        var tallest = 0;
        $('.offers-slider__slide-img-offer-offer').each(function () {
            if ( $(this).outerHeight() > tallest ) {
                tallest = $(this).outerHeight();
            }
        });
        $('.offers-slider__slide-img-offer-offer').css('height', tallest + 'px');
    }

    function setScrollbarThumbSize(carousel) {
        // Divide the width of the scrollbar by the number of slides to get the width of the thumb
        var offerSlideCount = $(carousel).parent().find('.slick-dots li').length;
        var offerScrollbarWidth = $(carousel).parents().find('.offers-slider__scrollbar').outerWidth();     //made parent plural to find div
        var offerThumbWidth = offerScrollbarWidth / offerSlideCount;
        $(carousel).parents().find('.offers-slider__scrollbar-thumb').css('width', offerThumbWidth);  //made parent plural to find div
    }

    function setScrollbarThumbPosition(carousel, nextSlideIndex) {
        // Move the thumb to the correct notch for the current slide index
        var slideIndex;
        if (typeof nextSlideIndex === 'undefined') {
            slideIndex = nextSlideIndex;
        } else {
            slideIndex = $(carousel).slick('slickCurrentSlide');
        }
        var scrollbarThumbWidth = $(carousel).parents().find('.offers-slider__scrollbar-thumb').outerWidth();      //made parent plural to find div
        var thumbPosition = nextSlideIndex * scrollbarThumbWidth;
        $(carousel).parents().find('.offers-slider__scrollbar-thumb').css('transform', 'translate(' + thumbPosition + 'px, -25%)');  //made parent plural to find div
    }
}





function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}

function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
	    ( (t >= 0) && (t <= h) ) ||
	    ( (b >= 0) && (b <= h) )
    ) {
	    return true;
    } else {
	    return false;
    }
}

/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
 function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);

    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === 'object') {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function() {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
            $.get(
                imgURL,
                function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                },
                'xml'
                );
        }
    });
}

function initGallery() {
	$('.gallery__carousel').slick({
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true
	});

	$('button.gallery__magnify').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup

		var galleryImageIndex = $(this).data('gallery-index') || 0;

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load($(this).parents('.gallery__carousel').data('gallery-slider'), function () {
           $modal.removeClass('loading').find('.gallery-modal__slider').slick({
              arrows: true,
              fade: true
          }).slick('slickGoTo', galleryImageIndex, false);
       });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
           $modal.remove();
       });
    });
}

function initPreRegister() {
    $('#add-guest-2').on('click', function() {
        $('.pre-register__form-guest-2').css('display', 'block');
        $('#remove-guest-2').css('display', 'inline-block');
        $('#add-guest-2').css('display', 'none');
    })

    $('#remove-guest-2').on('click', function() {
        $('#remove-guest-2').css('display', 'none');
        $('.pre-register__form-guest-2').css('display', 'none');
        $('#add-guest-2').css('display', 'inline-block');
    })

    $('#add-guest-3').on('click', function() {
        $('.pre-register__form-guest-3').css('display', 'block');
        $('#remove-guest-3').css('display', 'inline-block');
        $('#add-guest-3').css('display', 'none');
    })

    $('#remove-guest-3').on('click', function() {
        $('#remove-guest-3').css('display', 'none');
        $('.pre-register__form-guest-3').css('display', 'none');
        $('#add-guest-3').css('display', 'inline-block');
    })

    $('#add-guest-4').on('click', function() {
        $('.pre-register__form-guest-4').css('display', 'block');
        $('#remove-guest-4').css('display', 'inline-block');
        $('#add-guest-4').css('display', 'none');
    })

    $('#remove-guest-4').on('click', function() {
        $('#remove-guest-4').css('display', 'none');
        $('.pre-register__form-guest-4').css('display', 'none');
        $('#add-guest-4').css('display', 'inline-block');
    })
}

function initRegisterDatePicker() {
    if ( $('.pre-register').length === 0 )
        return;

    $('.register-custom-select').each(function () {
        // Allow "type to search" for select dropdowns of more than 10 items
        if ( $(this).find('option').length > 10 ) {
            $(this).find('select').selectric({
                search: true
            });
        } else {
            $(this).find('select').selectric();
        }
    });


    var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var nowTemp = new Date();
    var tomorrow = new Date();
        tomorrow.setDate(nowTemp.getDate() + 1);

    var $checkInField = $('#arrival');
    var $checkOutField = $('#departure');

    $checkInField.on('hide.datepicker', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');
        var dayAfterCheckIn = new Date(checkInDate);
            dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

        // Ensure checkout comes after checkin.
        // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
        if ( checkInDate >= checkOutDate ) {
            $checkOutField.datepicker('setDate', dayAfterCheckIn);
        }

        $checkOutField.datepicker('setStartDate', dayAfterCheckIn);

        $checkOutField[0].focus();
    });

    var checkin = $checkInField.datepicker({
        format: 'dd',
        autoPick: true,
        autoHide: true,
        date: null,
        startDate: nowTemp,
       language: datepickerLanguage,
        pick: function (e) {
            // User-facing date fields
            $('#arrival-month').text( monthNames[e.date.getMonth()] );
            $('#arrival-year').text( e.date.getFullYear() );

            // Formatted date for the booking engine
            $('input[name="DateIn"]').val( e.date.getDate() + '/' + pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getFullYear() );
        }
    });

    var checkout = $checkOutField.datepicker({
        format: 'dd',
        autoPick: true,
        autoHide: true,
        date: null,
        startDate: tomorrow,
       language: datepickerLanguage,
        pick: function (e) {
            // User-facing date fields
            $('#departure-month').text( monthNames[e.date.getMonth()] );
            $('#departure-year').text( e.date.getFullYear() );

            // Formatted date for the booking engine
            $('input[name="DateOut"]').val( e.date.getDate() + '/' + pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getFullYear() );
        }
    });


    $('#wedding').datepicker({
        format: 'dd',
        autoPick: false,
        autoHide: true,
        date: null,
        startDate: tomorrow,
          language: datepickerLanguage,
        pick: function (e) {
            // User-facing date fields
            $('#wedding-month').text( monthNames[e.date.getMonth()] );
            $('#wedding-year').text( e.date.getFullYear() );

            // Formatted date for the booking engine
            $('input[name="Wedding"]').val( e.date.getDate() + '/' + pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getFullYear() );
        }
    });

    $('#Guest1_DateOfBirth, #Guest2_DateOfBirth').datepicker({
        format: 'dd/mm/yyyy',
        autoPick: false,
        autoHide: true,
        date: null,
    });

    // Allow clicking the full container to open the calendar
    $('.date-wrap').on('click', function () {
        $(this).find('input[type="text"]').trigger('focus');
    });
}


function initLanguages() {
    if ( window.location.href.indexOf('/es/') > -1 ) {
        $('body').addClass('es');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="2">');
    }

    if ( window.location.href.indexOf('/fr/') > -1 ) {
        $('body').addClass('fr');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="3">');
    }

    if ( window.location.href.indexOf('/zh/') > -1 ) {
        $('body').addClass('zh');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="5">');
    }

    if ( window.location.href.indexOf('/de/') > -1 ) {
        $('body').addClass('de');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="7">');
    }

    if ( window.location.href.indexOf('/ru/') > -1 ) {
        $('body').addClass('ru');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="9">');
    }

    if ( window.location.href.indexOf('/ar/') > -1 ) {
        $('body').addClass('ar');
        $('.booking__form form').append('<input type="hidden" name="languageid" value="15">');
    }
}


function initOffersDetails() {
    // Sometimes the offer title is too long for the box (especially in Russian)
    // If so, move the main body below this text
   checkForWideOffersHeading();
   $(window).on('resize', checkForWideOffersHeading);
}

function checkForWideOffersHeading() {
    if ( ($('.offer-details__features-titles h1').outerWidth() > $('.offer-details__features-top').outerWidth()) && ( $(window).innerWidth() > 640 ) ) {
        $('.offer-details__info').css('padding-top', $('.offer-details__features-titles').outerHeight() + 40 + 'px' );
    } else {
        $('.offer-details__info').attr('style', '');
    }
}


function initMaps() {
    if(typeof google == 'undefined'){
        return;
    }
	var mapContainer = $('[data-map]');
    
    var mapConLat = mapContainer.data('map-center-coords') ? parseFloat(mapContainer.data('map-center-coords').split(',')[0]) : 0.00;
    var mapConLng = mapContainer.data('map-center-coords') ? parseFloat(mapContainer.data('map-center-coords').split(',')[1]) : 0.00;
    var mapZoom = mapContainer.data('map-zoom') || 8;
    var icon = "/assets/img/map-marker.svg"

    var mainResort = $('[data-main-resort]');
    var mainResLat = mainResort.data('marker-coordinates') ? parseFloat(mainResort.data('marker-coordinates').split(',')[0]) : 0.00;
    var mainResLng = mainResort.data('marker-coordinates') ? parseFloat(mainResort.data('marker-coordinates').split(',')[1]) : 0.00;
    var mainResLabel = mainResort.data('label')

    var markers = $('[data-markers]') || [];

    var map = new google.maps.Map(mapContainer[0], {
        			center: {lat: mapConLat, lng: mapConLng},
        			zoom: mapZoom,
        			zoomControl: true,
        			zoomControlOptions: {
        				position: google.maps.ControlPosition.RIGHT_BOTTOM
        			},
        			mapTypeControl: false,
        			scaleControl: false,
        			streetViewControl: false,
        			rotateControl: false,
        			fullscreenControl: false,
                    mapTypeId: 'satellite',
        		});

    var mainRes =  new google.maps.Marker({
        position: {lat: mainResLat, lng: mainResLng},
        map: map,
        icon: icon
    });

    var mainInfo = new google.maps.InfoWindow({
        content:'<p>' + mainResLabel + '</p>'
    })

    mainRes.addListener('click', function () {
        mainInfo.open(map, mainRes)
    })
    mainInfo.open(map, mainRes)

    markers.each(function () {
        var lat = parseFloat($(this).data('marker-coordinates').split(',')[0])
        var lng = parseFloat($(this).data('marker-coordinates').split(',')[1])

        var info = new google.maps.InfoWindow({
            content:'<p>' + $(this).data('label') + '</p>'
        })

        var marker = new google.maps.Marker({
            				position: {lat: lat, lng: lng},
            				map: map,
                            icon: icon
            			});

        marker.addListener('click', function () {
            info.open(map, marker)
        })
    })
}
